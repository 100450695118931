<template>
  <div class="layout-border">
    <div class="mainCont">
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div>
      <Star :totalStarNumber="totalStars" :currentIndex="answersfound" />
      <div class="background" :class="{ backgroundBlue: background == 'blue' }">
        <div class="game-content" :class="{ 'number-bg-color': !isNumberGame }">
          <div class="game-left-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in asideElementInfo.leftList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': isNumberGame }"
                @click="foundAnswer(item.id)"
              >
                <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium hanzi">{{ item.element }}</span>
              </div>
            </div>
          </div>

          <div
            class="game-center-box"
            v-for="(item, index) in questionInfoList"
            :key="index + 'question'"
            v-show="question === index"
          >
            <!-- 中间的背景图 -->
            <img class="objectDisplay fadeIn" :src="item.bgImg" />
            <!-- 中间的数字和pinyin -->
            <div class="number-area" v-if="isNumberGame">
              <span class="font-pinyin-large pinyin">{{ item.pinyin }}</span>
              <span class="font-hanzi-large hanzi">{{ item.hanzi }}</span>
            </div>
            <div class="speaker-wrapper" @click="playSoundWord" v-show="lessonId !== 59">
              <!-- <img :src="volumeImgSrc" alt="" class="speaker-img" /> -->
              <AudioPlayer
                :isPlay="isStartPlaying"
                :audioUrl="songUrl"
                :showAudioElement=" index > 0 ? false : true"
              />
            </div>
          </div>

          <div class="game-right-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in asideElementInfo.rightList"
                :key="index"
                class="item-content"
                :class="{ 'item-content-number': isNumberGame }"
                @click="foundAnswer(item.id)"
              >
                <span class="font-pinyin-medium pinyin" v-if="item.pinyin">{{
                  item.pinyin
                }}</span>
                <span class="font-hanzi-medium">{{ item.element }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import Star from "../Star";

import { startConfetti, playCorrectSound } from "@/utils/tools";
export default {
  components: {
    PageButton,
    AudioPlayer,
    Star,
  },
  props: {
    asideElementInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    questionInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    totalStars: {
      type: Number,
      require: true,
    },
    isNumberGame: {
      type: Boolean,
      require: true,
    },
    background: {
      type: String,
      require: false,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
    lessonId: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      gameSocket: {},
      audioSocketInfo: {},
      isLastStep: false,
      answersfound: 0,
      draggedStroke: 0,
      question: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      wordSoundUrl: this.questionInfoList[0].sound,
      isStartPlaying: null,
      songUrl: null,
    };
  },
  mounted() {
    this.$bus.$on("chooseElementToCheck", ({ index }) => {
      this.foundAnswer(index, true);
    });
    this.$bus.$on("chooseElementAudio", () => {
      this.playSoundWord("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("chooseElementToCheck");
    this.$bus.$off("chooseElementAudio");
  },
  watch: {
    gameSocket: {
      handler(value) {
        const data = {
          clickType: 20027,
          data: { value },
          text: "NumberClickGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002701,
          data: { value },
          text: "NumberClickGame点击喇叭socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    // 洗牌算法
    shuffle(arr) {
      let l = arr.length;
      let index, temp;
      while (l > 0) {
        index = Math.floor(Math.random() * l);
        temp = arr[l - 1];
        arr[l - 1] = arr[index];
        arr[index] = temp;
        l--;
      }
      return arr;
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.gameSocket = {
          index,
          value: Math.random(),
        };
      }
      let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      this.shuffle(arr);
      console.log(arr);

      if (this.questionInfoList[parseInt(this.question)].id == index) {
        this.answersfound++;
        playCorrectSound(true, false);
        if (this.question >= this.questionInfoList.length - 1) {
          playCorrectSound();
          console.log("祝贺");
          startConfetti();
          this.isLastStep = true;
          return;
        } else {
          this.wordSoundUrl =
            this.questionInfoList[parseInt(this.question) + 1].sound;
          this.question++;
        }
      } else {
        playCorrectSound(false);
      }
    },
    playSoundWord(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      this.songUrl = this.wordSoundUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
  .title-area {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 7%;
    width: 22%;
    height: 14%;
    background: #cd4c3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.backgroundBlue {
    background-image: url("../../../../assets/img/03-Backgrounds/background-factory.svg");
  }
}
.game-content {
  box-sizing: border-box;
  position: relative;
  width: -moz-fit-content;
  background: #214e96;
  width: 75%;
  height: 75%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;

  .game-left-box,
  .game-right-box {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    .game-aside-content {
      width: 100%;
      height: 95%;
      display: flex;

      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
    }
  }

  .game-center-box {
    width: 60%;
    // margin: 0 2.5%;
    height: 85%;
    border-radius: 30px;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80%;
      //   height: 100%;
      //   object-fit: cover;
    }
    .number-area {
      width: 50%;
      position: absolute;
      top: 32%;
      left: 18%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .pinyin,
      .hanzi {
        color: #224e96;
      }

      @media screen and (max-width: 1300px) {
        // external screen
        height: 28%;
      }
    }
    .speaker-wrapper {
      position: absolute;
      bottom: 5%;
      right: 10%;
      width: 50px;
      height: 34px;
      background-color: #224e96;
      border-radius: 50%;
      text-align: center;
      padding: 8px 0;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      .speaker-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 99%;
      }
    }
  }
}
.number-bg-color {
  background: #102c5a;
  box-sizing: border-box;
  border-top: 10px solid #233f6b;
  border-bottom: 10px solid #061329;
}

.item-content {
  box-sizing: border-box;
  width: 75%;
  z-index: 1;
  border-radius: 15px;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  padding: 5%;
}
.item-content-number {
  width: 45%;
}
// .item-content:active {
//   transform: scale(0.8);
// }
.item-content:hover {
  background-color: #912016;
  border-top: 8px solid #6e0c03;
  border-bottom: none;
}

// .objectDisplay {
//   position: absolute;
//   width: 70%;
//   /* bottom: 16%; */
//   height: 90%;
//   left: 15%;
//   top: 5%;
// }

.strokesFoundBoxes {
  position: absolute;
  right: 11%;
  top: 0;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px 25px 15px 25px;
  display: flex;
  // flex-direction: row;
  background-color: #f4d737;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 10px solid #d1b726;
  flex-flow: row;
  .strokesFoundimage {
    /* height: 13%; */
    width: 27px;
    /* min-width: 20px; */
    margin-top: 20%;
  }
  .outerBox {
    width: 95%;
    height: 100%;
    padding: 0px 4px;
    /* margin-right: 10px; */
    position: relative;
    display: flex;

    .innerBox {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #2472B3;
    }

    .innerBoxFound {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #F3D438;

      .strokesFoundimage {
        height: 65%;
        margin-top: 30%;
        // margin-left: 40%;
        margin-right: 5%;
      }
    }
  }
}
</style>
